import React from "react";
import IconCard from "../../../components/iconCard/iconCard";

const ResourceLinks = () => {
  return (
    <>
        <div className=" full p-b-0">
          <div className="mt-1 ">
            <p className="mb-2 fs-16">
            Our Care Managers can provide guidance around additional support resources. 
            </p>
            <div className="grid-cols-sm-1 grid-cols-2 gap-20">
            <IconCard
              alt="HealthCare"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="HealthCare.gov"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://healthcare.gov"
              modalLinkId="patient-sarclisa-resource-healthcare-gov"
            />
            <IconCard
              alt="Medicaid.gov"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="Medicaid.gov"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://medicaid.gov"
              modalLinkId="patient-sarclisa-resource-medicaid-gov"
            />
            <IconCard
              alt="Medicare.gov"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="Medicare.gov"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://medicare.gov"
              modalLinkId="patient-sarclisa-resource-medicare-gov"
            />
            <IconCard
              alt="Patient Advocate Foundation"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="Patient Advocate Foundation"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://www.patientadvocate.org/"
              modalLinkId="patient-sarclisa-resource-patientadvocate-org"
            />
            <IconCard
              alt="Patient Advocate Network Foundation"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="Patient Advocate Network Foundation"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://www.panfoundation.org/"
              modalLinkId="patient-sarclisa-resource-panfoundation-org"
            />
            <IconCard
              alt="American Cancer Society"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="American Cancer Society"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://cancer.org"
              modalLinkId="patient-sarclisa-resource-cancer-org"
            />
            <IconCard
              alt="Cancer Support Community"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="Cancer Support Community"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://www.cancersupportcommunity.org/"
              modalLinkId="patient-sarclisa-resource-cancersupportcommunity-org"
            />
            <IconCard
              alt="National Comprehensive Cancer Network<sup>®</sup>"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="National Comprehensive Cancer Network<sup>®</sup>"
              cardText=""
              hasButton={false}
              isModalLink={true}
              modalLink="https://www.nccn.org/patientresources/patient-resources"
              modalLinkId="patient-sarclisa-resource-nccn-org"
            />
            <IconCard
              alt="CancerCare"
              cardClass="external-link-card sarclisa clickable-title"
              isClickableTitle={true}
              cardTitle="CancerCare"
              cardText=""
              isModalLink={true}
              modalLink="https://www.cancercare.org/"
              modalLinkId="patient-sarclisa-resource-cancercare-org"
            />
              <IconCard
                alt="FindHelp.org"
                cardClass="external-link-card sarclisa clickable-title"
                isClickableTitle={true}
                cardTitle="FindHelp.org"
                cardText=""
                isModalLink={true}
                modalLink="https://www.Findhelp.org/"
                modalLinkId="patient-sarclisa-resource-findhelp-org"
              />
            </div>
          </div>
          <p className="hang m-b-0">
          *This listing is provided as a resource only and does not constitute an endorsement by Sanofi of any particular organization or its programming. Additional resources on this topic may be available and should be investigated. Sanofi does not review or control the content of non-Sanofi websites.              
          </p>
        </div>

        <div className=" full p-b-0">
          <div className="mt-1">
            <p className="mb-1">
              
            </p>
            
          </div>
          <p className="hang"></p>
        </div>
    </>
  );
};

export default ResourceLinks;
